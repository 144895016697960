import React from "react";
import { Navbar } from "../components/Navbar";
import { Carousel } from "../components/Carousel";
import { Helmet } from "react-helmet";

import pendant from "../artimages/pendant.jpg";
import pendant2 from "../artimages/pendant2.jpg";
import shoeowl from "../artimages/shoeowl.jpeg";

export function OvoPage() {
    const imageUrls = [
        `${pendant}`,
        `${shoeowl}`,
        `${pendant2}`,
    ]

    return (
        <div className="mx-md-5 mx-auto row d-md-flex flex-row">
            <Helmet>
                <meta name="description"
                    content="Owl Hyperfixation" />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Cappy, Strip, Beanie, Elf, Devil, Orange, Cap, Studios, Owl, Hyperfixation" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Navbar />
            <Carousel
                images={imageUrls}
            />
        </div>
    )
}