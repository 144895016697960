import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Marquee } from "../components/Marquee";

import next from "../images/nextanim.gif"
import khdvrdotnet from "../images/khadaverdotnetSQRE.png"



export function HomePage() {


    return (
        <div className="mx-md-5 d-flex flex-column justify-content-center align-items-center " style={{ height: '100dvh' }}>
            <Helmet>
                <meta name="description"
                    content="Scraps by KHADAVER" />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Cappy, Strip, Beanie, Elf, Devil, Orange, Cap, Studios" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <NavLink to="/scraps" className="row px-2" style={{ width: "50dvh" }} >
                <img src={khdvrdotnet} className="col-6 px-0" alt="khdvrdotnet"></img>
                <img src={next} className="col-6 px-0" alt="enter"></img>
                <Marquee />

            </NavLink>

        </div >
    )
}