import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom"
import { HomePage } from "../pages/HomePage";
import { ScrapsPage } from "../pages/ScrapsPage";
import { ContactPage } from "../pages/ContactPage";
import { AboutPage } from "../pages/AboutPage"
import { BlankPage } from "../pages/BlankPage"
import { CappyPage } from "../pages/CappyPage"
import { DeadManPage } from "../pages/DeadManPage"
import { FollowPage } from "../pages/FollowPage"
import { BlogPage } from "../pages/BlogPage"
import { OvoPage } from "../pages/OvoPage"
import "../styles.css"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="/contact-success" element={<ScrapsPage />} />
        <Route path="/scraps" element={<ScrapsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blank" element={<BlankPage />} />
        <Route path="/cappy" element={<CappyPage />} />
        <Route path="/deadman" element={<DeadManPage />} />
        <Route path="/follow" element={<FollowPage />} />
        <Route path="/ovo" element={<OvoPage />} />
        <Route path="/blog" element={<BlogPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
