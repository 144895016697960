import React from "react";
import { Navbar } from "../components/Navbar";
import { Marquee } from "../components/Marquee";

export function ContactPage() {
    return (
        <div className="mx-md-5 mx-auto row d-flex flex-row">
            <Marquee />

            <Navbar />

            <div className="container-md mt-md-5 col-md-6 col-12 mx-md-5 px-auto d-flex h-100 flex-column mt-4 pb-5 mb-4"  >
                <div className="border border-5 border-black ps-3"  >
                    <p className="text-break w-auto fs-5 mt-3">don't be shy</p>
                    <p className="fs-3 ">shoot me an email at <a rel="noreferrer" className="px-1 border border-black border-3 bg-black text-white rounded-0 " target="_blank" href="mailto:working@khdvr.net">working@khdvr.net</a></p>
                    <p className="mt-3 mb-3 fs-5">-OR-</p>
                    <form id='contact-form' data-netlify="true" method="post" action="/" name="contact" className='pe-3 pb-3' >
                        <input type="hidden" name="form-name" value="contact" />
                        <div className='mb-3'>
                            <label htmlFor='name' className='form-labe fs-3'>NAME</label>
                            <input type='text' id='name' name='name' required className='form-control border border-3 border-black rounded-0' />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='image' className='form-label fs-3'>EMAIL</label>
                            <input type='email' id='email' name='email' className='form-control border border-3 border-black rounded-0' />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='message' className='form-label fs-3'>MESSAGE</label>
                            <textarea id='message' name='message' required className='form-control border border-3 border-black rounded-0' cols="50" rows="10" />
                        </div>
                        <button type='submit' className='btn btn-lg btn-dark submit-button'><h1>SUBMIT</h1></button>
                    </form>
                </div>
            </div>

        </div >
    )
}