import React, { useState } from "react";

export function BlogPost(props) {

    const [showContent, setShowContent] = useState(false)




    return (
        <div className="blog-post mt-md-5 mt-3" >
            <h1 className="text-white fs-5 date-holder ps-1 mb-0">{props.date}</h1>
            <p onClick={() => { setShowContent(!showContent) }} className="container mt-0 border border-dark border-5 rounded-0 display-3 blog-title text-white overflow-hidden py-5" style={{ backgroundImage: `url(${props.background})` }}>
                {props.title}
            </p>
            <p className={"blog-text fs-3 px-3 pt-4 pb-3 mt-3 z-n1 fs-5 text-end overflow-auto border border-dark border-5 " + (showContent ? "" : "d-none")} >
                {props.text}
            </p>
        </div>

    )
}