import React from "react";
import { Navbar } from "../components/Navbar";
import { Carousel } from "../components/Carousel";
import { Helmet } from "react-helmet";
import { Marquee } from "../components/Marquee";

import deadman1 from "../artimages/deadman1.jpeg"
import deadman2 from "../artimages/deadman2.jpeg"
import deadman3 from "../artimages/deadman3.jpeg"
import deadman4 from "../artimages/deadman4.jpeg"
import deadman5 from "../artimages/deadman5.jpeg"
import deadmen from "../artimages/deadmen.jpg"
import deadmencolor from "../artimages/deadmencolor.jpg"
import deadmenbw from "../artimages/deadmenfinbw.jpg"



export function DeadManPage() {
    const imageUrls = [
        `${deadmencolor}`,
        `${deadmen}`,
        `${deadmenbw}`,
        `${deadman1}`,
        `${deadman2}`,
        `${deadman3}`,
        `${deadman4}`,
        `${deadman5}`,

    ]

    return (
        <div className="mx-md-5 mx-auto row d-md-flex flex-row" >
            <Helmet>
                <meta name="description"
                    content="in collaboration with Vera Yvan" />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Mirror, Graffiti , Orange, Cap, Studios" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Marquee />
            <Navbar />
            <Carousel
                images={imageUrls}
            />
        </div>
    )
}