import React, { useState } from "react"
import prev from "../images/prev.png"
import next from "../images/next.png"
import galleryButton from "../images/galleryanim.gif"


export function Carousel(props) {

    const [gallery, setGallery] = useState(false)

    const imageList = props.images.map((urls, index) => {
        if (index == 0) return <div className="carousel-item active" key={index}>
            <img src={urls} className="art-img d-block border border-dark border-2 w-100" alt="..." />
        </div>
        else return <div className="carousel-item" key={index}>
            <img src={urls} className="art-img d-block border border-dark border-2 w-100" alt="..." />
        </div>
    })

    const galleryImages = props.images.map((urls, index) => {
        return (
            <div className="col-6 col-md-4" key={index}>
                <img type="button" data-bs-toggle="modal" data-bs-target={"#gallery" + index} src={urls} className="gallery-img border border-dark border-2 w-100" alt="galleryimage" />
                <div id={"gallery" + index} tabindex="-1" className="modal fade border-dark border-2" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content" >
                            <a className="modal-body bg-black p-0 d-flex justify-content-center" data-bs-dismiss="modal">
                                <img className="modal-img mw-100" src={urls} alt="..." />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        )
    })

    function renderLogic() {
        if (gallery == false) {
            return (
                <div className="container-md col-md-8 col-12 my-md-5 my-3" >
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-interval="false" >
                        <div className="carousel-inner w-100">
                            {imageList}
                        </div>
                        <div className="container d-flex justify-content-md-end justify-content-center mt-2 ">
                            <div className="mt-1" type="button" onClick={() => setGallery(!gallery)}>
                                <img src={galleryButton} alt="gallery" style={{ width: '80px' }}></img>
                            </div>
                            <div className="mt-1" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <img src={prev} alt="prev" style={{ width: '80px' }}></img>
                            </div>
                            <div className="mt-1" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <img src={next} alt="next" style={{ width: '80px' }}></img>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else return (
            <div className="container-md col-md-8 col-12 my-md-5 my-3 pb-5 mb-5" >
                <div className="row">
                    <div>
                        <div className="container d-flex justify-content-md-end justify-content-center mb-2 ">
                            <div className="" type="button" onClick={() => setGallery(!gallery)}>
                                <img src={galleryButton} alt="gallery" style={{ width: '80px' }}></img>
                            </div>
                        </div>
                    </div>
                    {galleryImages}
                </div>
            </div>
        )
    }




    return (
        renderLogic()
    )
}


