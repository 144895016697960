import React from "react";
import { Navbar } from "../components/Navbar";
import { Marquee } from "../components/Marquee";
import { Carousel } from "../components/Carousel";
import { Helmet } from "react-helmet";


import insauce from "../artimages/insauce.jpg";
import abbatia from "../artimages/abbatia.jpg";
import heavy from "../artimages/heavy.jpg";
import redsauce from "../artimages/redsauce.jpg";
import tradoxy from "../artimages/tradoxy.jpeg"
import cappyoxi from "../artimages/cappyoxiairbrush.jpg"
import oxiair from "../artimages/oxiairbrush.jpg"
import oxiair2 from "../artimages/oxiairbrush2.jpg"
import oxisticker from "../artimages/oxisticker.jpg"
import cappyconcept from "../artimages/cappyconcept.jpg"
import comms from "../artimages/commissionsheetfin.jpg"
import tampa from "../artimages/chafa.jpg"
import yfn from "../artimages/yfn.jpg"
import pardon from "../artimages/pardon.jpg"
import deadman from "../artimages/deadmaninit.jpg"

export function ScrapsPage() {
    const imageUrls = [
        `${pardon}`,
        `${tampa}`,
        `${yfn}`,
        `${deadman}`,
        `${oxisticker}`,
        `${comms}`,
        `${cappyoxi}`,
        `${oxiair2}`,
        `${oxiair}`,
        `${redsauce}`,
        `${cappyconcept}`,
        `${insauce}`,
        `${abbatia}`,
        `${heavy}`,
        `${tradoxy}`,
    ]

    return (
        <div className="mx-md-5 mx-auto row d-md-flex flex-row">
            <Helmet>
                <meta name="description"
                    content="Scraps by KHADAVER" />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Cappy, Strip, Beanie, Elf, Devil, Orange, Cap, Studios" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Marquee />
            <Navbar />
            <Carousel
                images={imageUrls}
            />
        </div>
    )
}