import React from "react";
import { Navbar } from "../components/Navbar";
import { Helmet } from "react-helmet";
import { Marquee } from "../components/Marquee";

import follow from "../images/follow.png"

export function FollowPage() {
    return (
        <div className="mx-md-5 mx-auto row d-flex flex-row">
            <Marquee />

            <Helmet>
                <meta name="description"
                    content="Follow KHADAVER & Receive updates of all new developments." />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Cappy, Strip, Beanie, Elf, Devil, Orange, Cap, Studios, Follow" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Navbar />
            <div className="container-md mt-md-5 col-md-4 col-12 mx-md-5 px-auto d-flex h-100 flex-column mt-4 pb-5 mb-5"  >
                <p className="text-break w-auto fs-3 mt-md-5 text-uppercase ">a simple mailing list,</p>
                <p className="text-break w-auto fs-3 mt-2 text-uppercase ">interested in the worlds i'm building? i'll email occassional reminders* that i exist.</p>
                <a id="big-follow" className=" mt-4 d-inline" href="https://mailchi.mp/54c6a7b6fd6f/sign-up" target="_blank" rel="noreferrer">
                    <img className="" src={follow} ></img>
                </a>
                <p className="text-break w-auto fs-4 mt-4 ">*projects + shop + blog updates</p>


            </div>
        </div >
    )
}