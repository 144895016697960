import React from "react";
import { Navbar } from "../components/Navbar";
import { Helmet } from "react-helmet";
import { Marquee } from "../components/Marquee";
import { BlogPost } from "../components/BlogPost";

import deadmenblog from "../artimages/deadmenblog.jpg"
import mirrorman from "../artimages/MIRRORMAN3.jpg"
import oxitee from "../artimages/oxiblog.jpg"
import flyscreen from "../artimages/YUNGFLY.jpg"

export function BlogPage() {
    const blogText1 = [
        <div>I derive no pleasure from throwing my work up on the collective fidget toy that social media is designed to be.
            It has its uses but it'd be a disservice to me and the love I have for my ideas to not build a more permanent place for it all to live, so:<br /><br /> WELCOME TO KHADAVER DOT NET
            <br /><br /> These zoboomafoo ass social platforms deplete my sanity, initially my mind was set on dropping them altogether and pinning all my hopes on this site from the jump.<br /> I've gotten a little less stubborn.<br /><br />
            There's too much opportunity left on the table and avoidable friction if I were to commit to being a digital hermit. For the time being I want to have fun with it by treating other platforms as nothing but a billboard.
            I want this site to be the end all be all for engaging with my work, dragging you into a space that demands attention and hopefully rewards you for it rather than engaging with it in a space that treats all posts as discardable in an attempt to corral your attention.<br />
            <br />If the detour to this url is too big of an expectation, fuck em.<br />
            I think the practice of showing my own work respect can only benefit me every step of my process.<br />
            Here's to a productive year, I've gained the peace of mind to bring these ideas to life.
            <br /><br />
            END.

        </div>
    ]

    const blogText2 = [
        <div>
            IN LOVING MEMORY OF A DEADMAN WALKING, DEADMAN I AM.<br />JANUARY THE 19TH<br /><br />
            <div className="text-decoration-underline">CREATIVE DIR./ COLLAGE:</div><div>Vera Yvan</div><br />
            <div className="text-decoration-underline">ILLUS.:</div><div>KHADAVER</div><br />
            <div className="text-decoration-underline">SHOT BY:</div><div>couldntsayenough</div><br />
            <div className="text-decoration-underline">CAST&#40;from left to right, top to bottom&#41;:</div><div>Hanged Man, Melting, VeraYvan, Jeffrey B., Man Guillotine,<br /> Pig in a Banquet, Chairman, Wonton, Columbian</div>
        </div>
    ]


    return (
        <div className="mx-md-5 mx-auto row d-md-flex flex-row">
            <Helmet>
                <meta name="description"
                    content="What's KHADAVER up to?" />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Cappystrip, Cappy, Strip, Beanie, Elf, Devil, Orange, Cap, Studios" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Marquee />
            <Navbar />
            <div className="col-md-8 col-12 mb-5">
                <BlogPost
                    date={' TIP!!'}
                    background={mirrorman}
                    title={'TAP ON A POST.'}
                    text={'this one is empty though'}
                />
                <BlogPost
                    date={'2/9/24'}
                    background={flyscreen}
                    title={'BREAKING NEWS.'}
                    text={'WE IN A CONTENTCREATORDEMIC.'}
                />
                <BlogPost
                    date={'1/12/24'}
                    background={deadmenblog}
                    title={'DEADMAN.'}
                    text={blogText2}
                />
                <BlogPost
                    date={'1/3/24'}
                    background={oxitee}
                    title={'WELCOME.'}
                    text={blogText1}
                />
            </div>
        </div>
    )
}