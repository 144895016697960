import React from "react";
import { Navbar } from "../components/Navbar";
import { Helmet } from "react-helmet";
import { Marquee } from "../components/Marquee";

import khadman from "../artimages/KHADMAN.png"
import insta from "../images/insta.png"

export function AboutPage() {
    return (
        <div className="mx-md-5 mx-auto row d-flex flex-row">
            <Marquee />

            <Helmet>
                <meta name="description"
                    content="KHADAVER //kə`davər// : 1. blurs the lines between reality and the metaphysical, the jocose and the unnerving. 2. attempts to paint a visceral picture of their human experience." />
                <meta name="keywords"
                    content="KHADAVER, khdvr, Art, Comic, Artist, Philadelphia, Philly ,Mirror Man, Cappystrip, Cappy, Strip, Beanie, Elf, Devil, About, Orange, Cap, Studios" />
                <meta name="author" content="KHADAVER" />
            </Helmet>
            <Navbar />
            <div className="container-md  col-md-4 col-12 mx-md-5 px-auto d-flex h-100 flex-column mt-4 pb-5 mb-4"  >
                <img src={khadman} className="img-fluid  border border-5 border-black" ></img>
                <div className="" >
                    <h1 className="text-white fs-4 date-holder ps-3 mb-0">KHADAVER?</h1>
                    <p className="container mt-0 border border-dark border-5 rounded-0 display-3 khad-definition  overflow-hidden py-3">
                        <p className="text-break w-auto fs-4 mt-2 ">KHADAVER //kə`davər// : 1. blurs the lines between the jocose and the unnerving.</p>
                        <hr className="border border-black border-3 khadivider" />

                        <p className="fs-4 mt-3">shoot me an email at <a rel="noreferrer" className="px-1 border border-black border-3 bg-black text-white rounded-0 " target="_blank" href="mailto:working@khdvr.net">working@khdvr.net</a></p>
                        <p className="text-break w-auto fs-5 mt-2 mb-1">site crafted with my blood, sweat, and tears.</p>
                    </p>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/khdvrdotnet/"><img className="float-end mt-1" id="insta-icon" src={insta}></img></a>
                </div>
            </div>
        </div >
    )
}