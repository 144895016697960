import React from "react";
import { Navbar } from "../components/Navbar";

export function BlankPage() {
    return (
        <div className="mx-md-5 mx-auto row d-flex flex-row">
            <Navbar />
            <div className="container-md mt-md-5 col-md-8 col-12 my-5 px-auto d-flex flex-column mt-4"  >
                <h1 className="h1">NOTHING HERE</h1>
            </div>
        </div >
    )
}